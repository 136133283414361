<template>
  <div data-app>
    <v-data-table
      ref="dataTable"
      v-model="selected"
      :dense="dense ? dense : false"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage ? itemsPerPage : 20"
      :class="tableClass ? tableClass : ''"
      :search="search"
      :sortBy="sortBy ? sortBy : ''"
      :item-key="itemKey"
      :hide-default-header="hideHeader ? hideHeader : false"
      :hide-default-footer="hideFooter ? hideFooter : false"
      :show-group-by="showGroupBy ? showGroupBy : false"
      :show-select="showSelect ? showSelect : false"
      :loading="loading"
      :multi-sort="multiSort ? multiSort : false"
      :loading-text="loadingText ? loadingText : 'Cargando... Por favor espere'"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      style="margin-bottom: 10px"
    >
      <template v-slot:top>
        <v-row class="mx-0">
          <v-row class="ma-0 pl-3" align="center">
            <label class="ma-0 caption">Ordenar por:</label>
            <multiselect
              style="width: auto; min-width: 100px; padding-left: 10px"
              track-by="id"
              label="name"
              deselect-label=""
              select-label=""
              :placeholder="internalOrder.name"
              v-model="internalOrder"
              @input="$emit('orderEvent', internalOrder)"
              :options="orderOptions"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :allow-empty="false"
              :preserve-search="false"
            />
          </v-row>
          <v-spacer />
          <span style="padding: 0px 10px"
            ><strong style="font-size: 15px"
              >Total de resultados: {{ totalItems }}</strong
            >
          </span>
        </v-row>
      </template>
      <template v-slot:group.header="{ group, toggle, remove }">
        <th colspan="2">
          <v-icon @click.prevent="toggle()">mdi-toggle</v-icon>
          {{ group }}
          <v-icon @click.prevent="remove()">mdi-close</v-icon>
        </th>
      </template>
      <template v-slot:item.icon="{ item }">
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon>{{ !item.active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }" style="width: 100px">
        <v-tooltip top v-for="buttom in extraButtoms" :key="buttom.id">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              class="mr-1"
              :title="buttom.text"
              @click="handleRedirect(buttom, item)"
            >
              {{ buttom.icon }}
            </v-icon>
          </template>
          <span>{{ buttom.text }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-1"
              v-if="handleShowEdit(item)"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="handleShowDelete(item)"
              @click.prevent="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data
        ><div class="text-center" />
        {{ noDataText ? noDataText : 'No hay registros' }}
      </template>
      <template v-slot:no-results
        ><div class="text-center" />
        {{ noResultsText ? noResultsText : 'No hay resultados para su búsqueda' }}
      </template>
      <!--<template v-slot:footer>
			<div>This is a footer</div>
		</template>-->
    </v-data-table>
    <div
      class="text-center pt-2"
      style="margin: 10px 0px"
      v-if="showPagination === false ? showPagination : true"
    >
      <v-pagination
        @input="sendCurrentPage($event)"
        color="primary"
        v-model="currentPage"
        :length="pages"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: [
      'headers',
      'items',
      'loading',
      'loadingText',
      'sortBy',
      'multiSort',
      'tableClass',
      'dense',
      'showGroupBy',
      'hideHeader',
      'hideFooter',
      'showSelect',
      'totalItems',
      'title',
      'itemsPerPage',
      'page',
      'pages',
      'extraButtoms',
      'showCreate',
      'showEdit',
      'showDelete',
      'showPagination',
      'itemKey',
      'orderOptions',
      'noResultsText',
      'noDataText',
      'orderBy',
    ],
    components: {},
    data() {
      return {
        internalOrder: this.orderBy ? this.orderBy : '',
        search: '',
        selected: [],
        load: this.loading,
        options: {},
        item: [],
        dialogDelete: false,
        currentPage: this.page,
      }
    },
    watch: {
      orderBy(newValue) {
        this.internalOrder = newValue
      },
      page() {
        this.currentPage = this.page
      },
    },
    computed: {
      order: {
        get() {
          return {}
        },
        set(value) {
          return value
        },
      },
    },
    methods: {
      handleRedirect(buttom, item) {
        this.$emit(buttom.event.name, item)
      },
      handleShowEdit(item) {
        if (this.showEdit === false) {
          return false
        } else if (item.showEdit === false) {
          return false
        } else {
          return true
        }
      },
      handleShowDelete(item) {
        if (this.showDelete === false) {
          return false
        } else if (item.showDelete === false) {
          return false
        } else {
          return true
        }
      },
      editItem(item) {
        this.$emit('edit:item', item)
      },
      deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás esta información de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '¡Listo!',
              text: 'El registro fue eliminado',
              icon: 'success',
            })
            this.$emit('delete:item', this.item)
          }
        })
        this.item = Object.assign({}, item)
      },
      sendCurrentPage(page) {
        this.$emit('update:page', page)
      },
    },
  }
</script>

<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';

  .v-data-table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .multiselect__tags {
    height: 25px;
    min-height: 25px;
  }
  .multiselect__select {
    height: 25px;
  }
  .multiselect {
    height: 25px;
  }
</style>
