<template>
  <div class="vuse-content-wrapper course-lessons">
    <v-row no-gutters align="center" justify="space-between">
      <smart-breadcrumbs :items="navigationElements" />
      <help :helpId="45" />
    </v-row>
    <!-- Content -->
    <v-card>
      <div class="filters">
        <v-row align="center" class="mb-1 mx-0 text-center flex-column">
          <h2 class="">Lecciones</h2>
          <span class="text-body-2"> {{ course.name !== '' ? course.name : '' }}</span>
        </v-row>
        <v-row no-gutters justify="end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="#adadb0"
                dark
                @click.prevent="orderModal = true"
                >Ordenar
              </v-btn>
            </template>
            <span>Ordenar las lecciones</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="ml-2"
                @click.prevent="handleCreate()"
                >Crear
              </v-btn>
            </template>
            <span>Crear Lección</span>
          </v-tooltip>
        </v-row>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 2px 0">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getLessons()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getLessons()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Lección</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Lección</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row class="mx-0">
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-select
                  :rules="[rules.requiredSelection]"
                  v-model="type"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  label="Tipo"
                  single-line
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required]"
                  label="Duración"
                  v-model="duration"
                  suffix="min"
                  step="1"
                  min="1"
                  type="number"
                />
              </v-col>
              <v-col cols="12">
                <vue-editor
                  id="vue-editor1"
                  class="text-box vue-editor1"
                  placeholder="Descripción"
                  :useCustomImageHandler="true"
                  @image-added="handleImageAdded"
                  @image-removed="handleImageRemoved"
                  v-model="description"
              /></v-col>
              <v-col cols="12" lg="4" md="6" sm="12" v-if="type === 0">
                <v-text-field
                  counter
                  :rules="[rules.required, rules.url]"
                  label="Url"
                  v-model="url"
                />
              </v-col>
              <v-col cols="12" lg="5" md="6" sm="12" class="d-flex align-center">
                <v-row no-gutters align="center">
                  <img
                    v-if="flow === 'edit' && item.cover"
                    :src="item.cover.route"
                    :alt="item.name"
                    style="width: 40%"
                  />
                  <v-file-input
                    v-model="cover"
                    chips
                    :rules="[rules.fileSize]"
                    show-size
                    accept="image/png, image/jpeg, image/jpg"
                    :label="'Imagen de portada'"
                    style="width: 60%"
                  />
                </v-row>
              </v-col>

              <v-col
                cols="12"
                lg="5"
                md="6"
                sm="12"
                v-if="type === 2"
                class="d-flex align-center"
              >
                <v-row no-gutters align="center">
                  <img
                    v-if="flow === 'edit' && item.mainFile"
                    :src="item.mainFile.route"
                    :alt="item.name"
                    style="widthm: 40%"
                  />
                  <v-file-input
                    v-model="mainFile"
                    chips
                    :rules="[rules.multiFileSize]"
                    show-size
                    accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
                    :label="'Cargar documento'"
                    style="width: 60%"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="12">
                <v-checkbox :label="active ? 'Activo' : 'Inactivo'" v-model="active" />
              </v-col>
              <v-col class="cols-12">
                <documents-upload
                  :documents.sync="documents"
                  :show-toolbar="true"
                  :show-inputs="false"
                  :item="item"
                  :complement="true"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
    <v-dialog v-model="orderModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card style="padding: 10px 1rem; margin: 0px">
        <v-toolbar color="primary" light dense>
          <v-toolbar-title class="white--text"> Ordenar lecciones </v-toolbar-title>
        </v-toolbar>

        <v-list rounded dense>
          <v-list-item-group color="primary">
            <draggable
              class="list-group row"
              :list="list1"
              :group="'a'"
              tag="ul"
              :sort="true"
              @change="
                ''

              "
            >
              <v-list-item
                class="list-group-item col-lg-4 col-md-6 col-sm-12 pa-0"
                v-for="(lesson, index) in list1"
                :key="lesson.id"
              >
                <v-divider vertical />
                <v-list-item-content>
                  <h5 class="px-2">{{ lesson.name + ' #' + (index + 1) }}</h5>
                </v-list-item-content>
                <v-divider vertical />
              </v-list-item>
            </draggable>
          </v-list-item-group>
        </v-list>
        <v-row class="mx-0">
          <v-btn
            style="margin: auto; margin-top: 10px"
            dark
            color="primary"
            @click="updateOrder()"
          >
            Actualizar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="orderModal = false"
          >
            Cerrar
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { VueEditor } from 'vue2-editor'
  import Draggable from 'vuedraggable'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import DocumentsUpload from '@/components/CommonForModules/DocumentsUpload.vue'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'CourseLessons',
    components: {
      SimpleList,
      VueEditor,
      Draggable,
      DocumentsUpload,
    },
    data() {
      return {
        config: {},
        loading: true,
        orderModal: false,
        createdAt: '',
        toDay: new Date(),
        list1: [],
        course: {},
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Activa', value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        navigationElements: [
          {
            text: 'Elearning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/AdminPanel',
            href: '/business/elearning/admin-panel',
          },
          {
            text: 'Cursos',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/Courses',
            href: '/business/elearning/courses',
          },
          {
            text: 'Lecciones',
            disabled: true,
          },
        ],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 5, name: 'Orden', order: 'order', by: 'asc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
          { id: 5, name: 'Orden', order: 'order', by: 'asc' },
        ],
        filters: {
          search: '',
        },
        types: [
          { id: 0, name: 'Video' },
          { id: 1, name: 'Texto' },
          { id: 2, name: 'Documento' },
        ],
        cover: null,
        mainFile: null,
        name: '',
        documents: [],
        description: '',
        url: '',
        duration: '',
        active: true,
        type: 0,
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          requiredSelection: (v) => v !== null || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            v ? v.size < 1024000 : true || 'El peso del archivo debe ser inferior a 1mB',
          multiFileSize: (v) =>
            v ? v.size < 10240000 : true || 'El peso del archivo debe ser inferior a 5mB',
        },
        index: 100,
      }
    },
    watch: {
      company() {
        this.getLessons()
      },
    },
    created() {
      this.getCourse()
      this.getLessons()
      this.getAllLessons()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      // handleNewFile() {
      //   this.supportMaterials.push({ name: '', file: null })
      // },
      // handleRemoveFile(index) {
      //   if (this.supportMaterials.length === 1) {
      //     this.supportMaterials = [{ name: '', file: null }]
      //   } else if (this.supportMaterials.length > 1) {
      //     this.supportMaterials.splice(index, 1)
      //   }
      // },

      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },

      async getCourse() {
        await this.$axios
          .get('courses/' + this.$route.params.courseId + '?with[]=cover')
          .then((response) => {
            this.course = response.data
          })
      },

      async getAllLessons() {
        await this.$axios
          .get(
            'lessons?order=order&by=asc&limit=300&course_id=' +
              this.$route.params.courseId
          )
          .then((response) => {
            this.list1 = response.data
          })
      },

      async getLessons() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'lessons?with[]=cover&with[]=uploads&course_id=' +
              this.$route.params.courseId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((lesson) => {
              lesson.activeText = lesson.active ? 'Si' : 'No'
              lesson.uploads.forEach((upload) => {
                const split = upload.route.split('.')
                upload.format = split[split.length - 1]
              })
              lesson.mainFile = lesson.uploads.find((upload) => upload.type === 'main')
              // lesson.supportMaterials = lesson.uploads.filter(
              //   (upload) => upload.type === 'document'
              // )
            })

            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.description = ''
        this.description = ''
        this.url = ''
        this.duration = ''
        this.active = true
        this.type = 0
        this.cover = null
        this.mainFile = null
        // this.supportMaterials = [{ name: '', file: null }]

        this.$modal.show('crudModal')
        // this.onPaste()
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getLessons()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = Object.assign(this.item, item)
        this.name = this.item.name
        this.description = this.item.description
        this.url = this.item.url
        this.duration = this.item.duration
        this.active = this.item.active
        this.type = this.item.type
        this.cover = null
        this.mainFile = null
        // this.supportMaterials = [{ name: '', file: null }]
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
        // this.onPaste()
      },

      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }
        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }
        const formData = new FormData()
        formData.append('course_id', this.$route.params.courseId)
        formData.append('name', this.name)
        formData.append('description', this.description)
        if (this.url) {
          formData.append('url', this.url)
        }
        formData.append('duration', this.duration)
        formData.append('active', this.active)
        formData.append('type', this.type)
        if (this.cover && this.cover !== '' && this.cover !== undefined) {
          formData.append('files[0][file]', this.cover)
          formData.append('files[0][fileType]', 'cover')
        }
        if (this.mainFile && this.mainFile !== '' && this.mainFile !== undefined) {
          formData.append('files[1][file]', this.mainFile)
          formData.append('files[1][fileType]', 'main')
        }
        if (this.documents.length > 0 && this.documents !== [{ name: '', file: null }]) {
          let index = 2
          this.documents.forEach((material) => {
            if (material.file) {
              formData.append('files[' + index + '][fileName]', material.name)
              formData.append('files[' + index + '][file]', material.file)
              formData.append('files[' + index + '][fileType]', 'document')
              index++
            }
          })
        }
        this.$axios.post('lessons', formData).then(() => {
          this.page = 1
          this.getLessons()
          this.getAllLessons()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }
        const formData = new FormData()
        formData.append('course_id', this.$route.params.courseId)
        formData.append('name', this.name)
        formData.append('description', this.description)
        if (this.url) {
          formData.append('url', this.url)
        }
        formData.append('duration', this.duration)
        formData.append('active', this.active)
        formData.append('type', this.type)
        if (this.cover && this.cover !== '' && this.cover !== undefined) {
          formData.append('files[0][file]', this.cover)
          formData.append('files[0][fileType]', 'cover')
          if (this.item.cover) {
            formData.append('files[0][fileId]', this.item.cover.id)
          }
        }
        if (this.mainFile && this.mainFile !== '' && this.mainFile !== undefined) {
          formData.append('files[1][file]', this.mainFile)
          formData.append('files[1][fileType]', 'main')
          if (this.item.mainFile) {
            formData.append('files[1][fileId]', this.item.mainFile.id)
          }
        }
        if (this.documents.length > 0 && this.documents !== [{ name: '', file: null }]) {
          let index = 2
          this.documents.forEach((material) => {
            if (material.file) {
              formData.append('files[' + index + '][fileName]', material.name)
              formData.append('files[' + index + '][file]', material.file)
              formData.append('files[' + index + '][fileType]', 'document')
              index++
            }
          })
        }
        formData.append('_method', 'patch')

        this.$axios.post('lessons/' + this.item.id, formData).then(() => {
          this.page = 1
          this.getLessons()
          this.getAllLessons()
          this.$modal.hide('crudModal')
        })
      },

      onPaste() {
        const elements = document.getElementsByClassName('vue-editor1')
        for (let i = 0; i < elements.length; i++) {
          elements[i].addEventListener('paste', (e) => {
            e.stopPropagation()
            e.preventDefault()
            const text = e.clipboardData.getData('text/plain')
            if (document.queryCommandSupported('insertText')) {
              document.execCommand('insertText', false, text)
            } else {
              document.execCommand('paste', false, text)
            }
          })
        }
      },

      handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        if (this.flow === 'edit') {
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
              Authorization: this.$session.get('tokenSession')
                ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
                : 'Bearer ',
            },
          }
          const formData = new FormData()
          formData.append('files[0][file]', file)
          formData.append('files[0][fileType]', 'image')
          formData.append('_method', 'patch')
          this.$axios
            .post('lessons/' + this.item.id + '?with[]=latestUpload', formData, config)
            .then((response) => {
              const url = response.data.lesson.latest_upload.route // Get url from response
              Editor.insertEmbed(cursorLocation, 'image', url)
              resetUploader()
            })
        } else {
          this.$swal({
            title: 'Ups...',
            text: 'Para agregar imágenes primero debes crear la lección.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'De acuerdo',
          })
          resetUploader()
        }
      },
      handleImageRemoved(file) {
        if (file.startsWith(process.env.VUE_APP_SPACES_DOMAIN)) {
          this.$axios.post('upload-management/delete-upload-by-url', {
            url: file,
            _method: 'delete',
          })
        }
      },

      updateOrder() {
        this.$axios.patch('sync-lessons-order', { lessons: this.list1 }).then(() => {
          this.getLessons()
          this.getAllLessons()
          this.orderModal = false
        })
      },

      async deleteItem(item) {
        this.$axios.delete('lessons/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getLessons()
          this.getAllLessons()
        })
      },

      // async deleteFile(file) {
      //   this.$axios.delete('upload-management/delete-upload/' + file.id).then(() => {
      //     this.item.supportMaterials = this.item.supportMaterials.filter(
      //       (upload) => upload.id !== file.id
      //     )
      //     this.index++
      //     this.$swal({
      //       icon: 'success',
      //       title: `Eliminado`,
      //       showCancelButton: false,
      //       showConfirmButton: true,
      //       timer: 2000,
      //     })
      //   })
      // },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getLessons()
      },
      docsIcons(text) {
        let icon
        switch (text) {
          case 'pdf':
            icon = 'mdi-file-pdf-box'
            break
          case 'doc':
            icon = 'mdi-microsoft-word'
            break
          case 'docx':
            icon = 'mdi-microsoft-word'
            break
          case 'xls':
            icon = 'mdi-microsoft-excel'
            break
          case 'xlsx':
            icon = 'mdi-microsoft-excel'
            break
          case 'ppt':
            icon = 'mdi-microsoft-powerpoint'
            break
          case 'pptx':
            icon = 'mdi-microsoft-powerpoint'
            break

          default:
            icon = 'mdi-folder'
            break
        }
        return icon
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .course-lessons {
    .flip-list-move {
      transition: transform 1s;
    }
    .no-move {
      transition: transform 0.5s;
    }
    .ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }
    .list-group {
      min-height: 20px;
    }
    .list-group-item {
      cursor: move;
      list-style: none;
    }
    .list-group-item i {
      cursor: pointer;
    }
  }
</style>
