<template>
  <v-card class="documents-upload">
    <v-toolbar
      class="position-fixed"
      color="primary"
      light
      height="40"
      v-if="showToolbar"
    >
      <v-toolbar-title class="white--text">
        {{ inputs ? uploadTitle : title }}
      </v-toolbar-title>
      <v-spacer />

      <v-scroll-x-reverse-transition v-if="!complement">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              v-show="inputs"
              color="grey lighten-5"
              class="ma-1"
              @click="saveFiles()"
              :disabled="!valid"
            >
              <v-icon>{{
                valid ? 'mdi-cloud-upload' : 'mdi-cloud-upload-outline'
              }}</v-icon>
            </v-btn>
          </template>
          <span>Guardar</span>
        </v-tooltip>
      </v-scroll-x-reverse-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-bind="attrs"
            v-on="on"
            class="ma-1"
            color="white"
            v-if="!complement"
            @click="inputs = !inputs"
          >
            <v-icon>{{ inputs ? 'mdi-file-plus' : 'mdi-file-plus-outline' }} </v-icon>
          </v-btn>
        </template>
        <span>Subir documentos</span>
      </v-tooltip>
    </v-toolbar>

    <v-expand-transition>
      <v-card elevation="0" v-show="complement || (showToolbar && inputs)">
        <v-form v-model="valid">
          <v-row class="mx-0 mt-4" v-for="(file, index) in files" :key="index">
            <v-col cols="10" sm="6">
              <v-text-field
                hide-details="auto"
                v-model="file.name"
                label="Nombre del archivo"
              />
            </v-col>
            <v-col cols="10" sm="5">
              <v-file-input
                v-model="file.file"
                chips
                show-size
                :clearable="false"
                hide-details="auto"
                accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.png,.jpg,.jpeg,.webp,.avif "
                label="Cargar archivo"
                :rules="[rules.required, rules.fileSize]"
              />
            </v-col>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                    v-bind="attrs"
                    v-on="on"
                 -->
            <v-scroll-x-reverse-transition>
              <v-btn
                title="Eliminar Archivo"
                icon
                rounded
                color="primary"
                class="align-self-center mt-n12 mt-sm-0 mx-auto col-2 col-sm-1"
                v-show="files.length > 1"
                @click="handleRemoveFile(index)"
                ><v-icon>mdi-delete</v-icon></v-btn
              ></v-scroll-x-reverse-transition
            >
            <!-- </template>
              <span>Eliminar Archivo</span>
            </v-tooltip> -->
          </v-row>
          <v-row class="mx-0" justify="end">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                v-bind="attrs" v-on="on"
                -->
            <v-btn small text @click="handleNewFile()">Añadir</v-btn>
            <!-- </template>
              <span>Añadir Archivo</span>
            </v-tooltip> -->
          </v-row>
        </v-form>
      </v-card>
    </v-expand-transition>

    <v-card
      flat
      class="mx-3 mt-2"
      :key="loaderIndex"
      max-height="250"
      style="overflow: auto"
    >
      <v-row
        no-gutters
        class="flex-column"
        justify="center"
        align="center"
        v-if="creating || loadingInfo || documents.length === 0"
      >
        <v-progress-circular
          :indeterminate="loadingInfo || creating"
          color="primary"
          v-if="loadingInfo || creating"
        />
        <v-expand-transition
          ><span class="caption" v-show="creating">
            {{ creatingText }}
          </span></v-expand-transition
        >
        <v-expand-transition>
          <span class="caption" v-show="loadingInfo">
            {{ loadingText }}
          </span></v-expand-transition
        >
      </v-row>

      <v-card-text
        flat
        v-if="!creating && !loadingInfo && documents.length === 0 && loadText"
        height="50"
        :outlined="inputs"
        :rounded="inputs"
        width="100%"
        class="d-flex align-center justify-center"
      >
        {{ inputs ? loadText : emptyText }}
      </v-card-text>
      <v-row class="mx-0 mt-2" :key="index">
        <v-col cols="12" sm="6" v-for="(file, index) in documents" :key="index">
          <v-list-item link class="px-0">
            <v-btn class="mr-1" icon>
              <v-icon x-large>
                {{ docsIcons(file.route) }}
              </v-icon>
            </v-btn>
            <v-list-item-content>
              <v-list-item-title
                :title="file.name ? file.name : 'Archivo ' + (file.id + 1)"
                >{{
                  file.name ? file.name : 'Archivo ' + (file.id + 1)
                }}</v-list-item-title
              >

              <v-list-item-subtitle
                >{{ file.format }}
                {{ file.size ? ' | ' + file.size + 'kB' : '' }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <a :href="file.route" download="document.pdf" style="text-decoration: none">
                <v-btn tag="a" icon small>
                  <v-icon color="grey lighten-1"> mdi-download </v-icon>
                </v-btn>
              </a>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon small @click="deleteFile(file)">
                <v-icon color="red lighten-1"> mdi-delete </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'DocumentsUpload',
    created() {
      this.generateDocuments(this.item)

      if (!this.complement && typeof this.saveUrl === 'undefined') {
        console.error("property 'saveUrl' is required in 'documents-upload' component.")
      }
      this.$emit('update:documents', this.files)
    },
    data() {
      return {
        valid: false,
        config: {},
        inputs: this.showInputs,
        toolbar: this.showToolbar,
        files: [{ name: '', file: null }],
        documents: [],
        index: 0,
        loaderIndex: 100,
        creating: false,
        loadingInfo: this.loading,

        rules: {
          required: (v) => !!v || 'Requerido.',
          // max: (v) =>
          //   (v ? v.length <= 400 : true) || 'Debe poser menos de 400 caracteres',
          fileSize: (v) =>
            !v || v.size < 5120000 || 'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: false,
        default: 'Documentos cargados',
      },
      uploadTitle: {
        type: String,
        required: false,
        default: 'Sube un documento',
      },
      creatingText: {
        type: String,
        required: false,
        default: 'Cargando documentos en el sistema',
      },
      loading: { type: Boolean, required: true },
      loadingText: {
        type: String,
        required: false,
        default: 'Generando visualización... solo un segundo',
      },
      loadText: {
        type: [String, Boolean],
        required: false,
        default: 'Los archivos que cargues se veran acá ',
      },
      emptyText: {
        type: [String, Boolean],
        required: false,
        default: 'Aún no hay documentos cargados',
      },
      saveUrl: {
        type: String,
        default: undefined, // this value is intended to create a console error when the property has not been declared. This is because it depends on the 'complement' prop to be required or not.
      },
      complement: {
        type: Boolean,
        required: true,
      },
      showToolbar: {
        type: Boolean,
        required: false,
        default: true,
      },
      showInputs: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      showLoadText() {
        if (typeof this.loadText === 'boolean') {
          return this.loadText
        } else {
          return true
        }
      },
      showEmptyText() {
        if (typeof this.emptyText === 'boolean') {
          return this.emptyText
        } else {
          return true
        }
      },
    },
    watch: {
      item(newValue) {
        this.generateDocuments(newValue)
      },
      files(newValue) {
        if (this.complement) {
          this.$emit('update:documents', newValue)
        }
      },
    },
    methods: {
      generateDocuments(item) {
        this.loadingInfo = true
        if (item.uploads && item.uploads.length > 0) {
          this.documents = item.uploads.filter((upload) => upload.type === 'document')
          this.loadingInfo = false
        } else {
          this.loadingInfo = false
        }
      },
      handleNewFile() {
        this.files.push({ name: '', file: null })
      },
      handleRemoveFile(index) {
        // if (this.files.length === 1) {
        //   this.files = [{ name: '', file: null }]
        // } else
        if (this.files.length > 1) {
          this.files.splice(index, 1)
        }
      },
      async saveFiles() {
        this.creating = true
        this.inputs = !this.inputs

        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }
        const formData = new FormData()

        if (this.files !== [{ name: '', file: null }]) {
          this.files.forEach((material, index) => {
            if (material.file !== null) {
              formData.append('files[' + index + '][fileName]', material.name)
              formData.append('files[' + index + '][file]', material.file)
              formData.append('files[' + index + '][fileType]', 'document')
            }
          })
        }
        formData.append('_method', 'patch')
        this.$axios.post(this.saveUrl, formData, this.config).then(() => {
          this.$emit('reloadItem')
          this.creating = false
          this.files = [{ name: '', file: null }]
          // this.loaderIndex++
        })
      },
      async deleteFile(file) {
        this.$axios.delete('upload-management/delete-upload/' + file.id).then(() => {
          this.documents = this.documents.filter((upload) => upload.id !== file.id)
          this.loaderIndex++
          this.$swal({
            icon: 'success',
            title: `Eliminado`,
            showCancelButton: false,
            showConfirmButton: true,
            timer: 2000,
          })
        })
      },
      docsIcons(text) {
        const indicator = text.split('.').pop()
        let icon
        switch (indicator) {
          case 'pdf':
            icon = 'mdi-file-pdf-box'
            break
          case 'doc':
            icon = 'mdi-microsoft-word'
            break
          case 'docx':
            icon = 'mdi-microsoft-word'
            break
          case 'xls':
            icon = 'mdi-microsoft-excel'
            break
          case 'xlsx':
            icon = 'mdi-microsoft-excel'
            break
          case 'ppt':
            icon = 'mdi-microsoft-powerpoint'
            break
          case 'pptx':
            icon = 'mdi-microsoft-powerpoint'
            break

          default:
            icon = 'mdi-folder'
            break
        }
        return icon
      },
    },
  }
</script>

<style lang="scss">
  .documents-upload {
    .theme--light.v-btn.v-btn--disabled,
    .theme--light.v-btn.v-btn--disabled .v-icon,
    .theme--light.v-btn.v-btn--disabled .v-btn__loading {
      color: #bdbdbd !important;
    }
  }
</style>
